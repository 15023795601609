import React, { useState, useEffect, useContext } from 'react';
import { httpss } from '../../utils/host';
import { IoIosClose } from "react-icons/io";
import Notification from '../layout/Noti'
import dataSelectSearch from '../../assets/data/select.json';
import { GlobalState } from '../../store/GlobalState';

export default function EditForm({ onClose,refreshData , id, data}) {
  const state = useContext(GlobalState);
  const userInfo = state?.userInfos?.userInfos[0]
  const LinhVucList = state?.dataList?.dataList[0]?.LinhVucs.map(item => item.LinhVucName);
  const NoiBHList = state?.dataList?.dataList[0]?.NoiBHs.map(item => item.NoiBHName);
  const LoaiVBList = state?.dataList?.dataList[0]?.LoaiVBs.map(item => item.LoaiVBName);

  const [notification, setNotification] = useState(null);
  const showNotification = (message) => {setNotification({ message });};
  const handleCloseNotification = () => {setNotification(null);};

  const [formData, setFormData] = useState(data);
  // console.log(1,formData)
  
  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [fieldName]: value,
      };
      if (fieldName === 'TepDinhKem') {
        updatedData.TenTep = extractFileNameFromUrl(value);
      }
      return updatedData;
    });
  };
  console.log(formData)
  const extractFileNameFromUrl = (url) => {
    try {
      const params = new URLSearchParams(url.split('?')[1]);  
      const idParam = params.get('id'); 
  
      if (idParam) {
        const decodedId = decodeURIComponent(idParam);  
        const parts = decodedId.split('/'); 
        return parts[parts.length - 1];  
      }
  
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1]; 
    } catch (error) {
      console.error('Invalid URL:', error);
    }
  
    return ''; // Trả về chuỗi rỗng nếu không tìm được tên file
  };

  const handleClose = (e) => {
    if (e.target.id === 'form') {
      onClose();
    }
  };

  const updateVanBan = async () => {
    try {
      const url = `${httpss}/app/users/vanban_update`;
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(
          {
            ...formData,
            LoaiVBName: formData.LoaiVBName?.LoaiVBName || formData.LoaiVBName, // Đảm bảo là chuỗi
            NoiBHName: formData.NoiBHName?.NoiBHName || formData.NoiBHName, // Đảm bảo là chuỗi
            LinhVucName: formData.LinhVucName?.LinhVucName || formData.LinhVucName, // Đảm bảo là chuỗi
          }
        )
      });

      if (response.ok) {
        const result = await response.json();
        refreshData();
        onClose();
        alert(result.message);
      } else {
        console.error('Request failed:', response.status);
      }
    } catch (error) {console.error('Error:', error)
      // window.location.reload();
    }
  };

  return (
    <div id="form" className='z-10 w-full h-full fixed top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-black/60' onClick={handleClose}>
      <div id="form" className='fixed mx-auto w-[90vw] top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 flex justify-center items-center'>
        <div className="bg-white p-3 rounded-lg border w-full">
          <div className='flex items-center justify-between text-[#0048E0] font-bold'>
            <div></div>
            <div>Chỉnh sửa {formData.KyHieu}</div>
            <button onClick={onClose} className="rounded-lg hover:bg-blue-500 hover:text-white text-gray-400">
              <IoIosClose size={25} />
            </button>
          </div>
          <hr />
          {notification && (<Notification message={notification.message} onClose={handleCloseNotification} />)}

          <form onSubmit={(e)=>e.preventDefault()}>
            {/* <div className='flex flex-col h-[75vh] overflow-y-auto'>
              <TextField  id="standard-basic" variant="standard" label="Họ và tên" value={formData.name} autoFocus onChange={(e) => handleInputChange('name', e.target.value)} className='my-2 font-bold text-center'/>
              
            </div> */}
            <div className='flex flex-col overflow-auto'>  {/* h-[80vh] */}
              <div className='p-2 m-2 rounded-lg'>
                <div className='md:flex gap-3 mb-2'>
                  <div className='w-full'>
                    <label className="block text-sm font-medium">Số/ ký hiệu</label>
                    <input required type="text" autoFocus value={formData.KyHieu} onChange={(e) => handleInputChange('KyHieu', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                  </div>
                  <div className='w-full'>
                    <label className='block text-sm font-medium'>Ngày ban hành:</label>
                    <input required onChange={(e) => handleInputChange('NgayBanHanh', e.target.value)} value={formData.NgayBanHanh} type="date" className='w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow'/>
                  </div>
                </div>
                <div className='md:flex gap-3 mb-2'>
                  <div className='w-full'>
                    <label className="block text-sm font-medium">Loại văn bản</label>
                    <input required list='loaiVB' type='search' value={formData.LoaiVBName?.LoaiVBName} onChange={(e) => handleInputChange('LoaiVBName', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                    <datalist id='loaiVB'>
                      {LoaiVBList?.map((item, index) => (
                        <option key={index} value={item}/>
                      ))}
                    </datalist>
                  </div>
                  <div className='w-full'>
                    <label className="block text-sm font-medium">Đơn Vị Ban Hành</label>
                    <input required list='noiBH' type='search' value={formData.NoiBHName?.NoiBHName} onChange={(e) => handleInputChange('NoiBHName', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                    <datalist id='noiBH'>
                        {NoiBHList?.map((item, index) => (
                          <option key={index} value={item}/>
                        ))}
                    </datalist>
                  </div>
                </div>
                <div className='mb-2'>
                  <label className="block text-sm font-medium">Trích yếu</label>
                  <input required type="text" value={formData.TrichYeu} onChange={(e) => handleInputChange('TrichYeu', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                </div>
                  <div className='w-full'>
                      <label className="block text-sm font-medium">Lĩnh vực</label>
                      <input required list='linhVuc' type='search' value={formData.LinhVucName?.LinhVucName} onChange={(e) => handleInputChange('LinhVucName', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                      <datalist id='linhVuc'>
                        {LinhVucList?.map((item, index) => (
                          <option key={index} value={item}/>
                        ))}
                      </datalist>
                    </div>
                    <div className='mb-2'>
                      <label className="block text-sm font-medium">Link tài liệu đính kèm (SharePoint)</label>
                      <textarea required type="text" value={formData.TepDinhKem} onChange={(e) => handleInputChange('TepDinhKem', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                    </div>

                    <div className='mb-2'>
                      <label className="block text-sm font-medium">Tên tệp</label>
                      <input required type="text" value={formData.TenTep} onChange={(e) => handleInputChange('TenTep', e.target.value)} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                    </div>

              </div>
            </div>
            <div className='flex justify-end pt-2 gap-2'>

              <button className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold'
                onClick={updateVanBan}>Lưu
              </button>
              <button className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold'
                onClick={onClose}>Hủy
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
