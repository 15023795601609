import React, {useState, useEffect} from 'react'
import { FaYoutube, FaFacebook, FaTiktok } from "react-icons/fa";
import '../../styles/styleHome.css'
import LogoHH from '../../assets/images/logoHH.png'
import logoHHHSG from '../../assets/images/HHH-SG.png'

export default function Header(params) {
  const {isMenu, setIsMenu} = params
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {setScrolled(window.scrollY > 0)};
    window.addEventListener('scroll', handleScroll);
    return () => {window.removeEventListener('scroll', handleScroll);};
  }, []);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
    };
  
  return (
    <div onClick={scrollToTop} className={`flex sticky z-10 bg-white top-0 py-2 transition-all duration-300 shadow`}>
        {/* <button onClick={()=>{setIsMenu(!isMenu)}} className="p-2 mx-2 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-900 hover:bg-gray-100">
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
        </button> */}
        
        <div className='flex justify-center items-center mx-7 max-md:mx-2 w-full'>
            <div className='flex text-3xl max-md:text-[12px] gap-1'>
            <a href="https://hunghau.vn" target="_blank" className="">
              <img src={logoHHHSG} className='h-10'/>
            </a>
            </div>
        </div>
    </div>
  )
}
