import React, { useState, useEffect, useContext } from 'react'
import { IoIosClose } from "react-icons/io";
import { httpss } from '../../utils/host';
import dataSelectSearch from '../../assets/data/select.json';
import { GlobalState } from '../../store/GlobalState';

export default function AddVanBan({onClose, refreshData, data}) {
  const state = useContext(GlobalState);
  const KyHieuList = state?.dataList?.dataList[0]?.VanBans.map(item => item.KyHieu);
  // console.log(KyHieuList)
  const [KyHieu, setKyHieu] = useState('');
  const [KyHieuLienQuan, setKyHieuLienQuan] = useState('');
  const handleKyHieu = (e) => {setKyHieu(e.target.value)}
  const handleKyHieuLienQuan = (e) => {setKyHieuLienQuan(e.target.value)}

  const handleClose = (e) => {e.target.id === 'form' && onClose()};

  const handleAdd = async (e) => {
    e.preventDefault()
    try {
      const url = `${httpss}/app/users/vanban_lienquan`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        }, body: JSON.stringify({ KyHieu:data.KyHieu, KyHieuLienQuan, })});
        
          if (response.ok) {
            const result = await response.json();
            console.log(result)
            onClose()
            refreshData()
        } else {
          console.error('Request failed:', response.status);
          alert('Vui lòng thử lại')}
      } catch (error) {console.error('Lỗi:', error); }
    };

  return (
    <div id="form" className='z-10 w-full h-full fixed top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-black/60' onClick={handleClose}>
    <div id="form" className='fixed mx-auto container px-5 top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 flex justify-center items-center'>
      <div className="bg-white p-3 rounded-lg border md:w-1/2 max-md:w-full">
        <div className='flex items-center justify-between text-[#0048E0] font-bold'>
          <div></div>
          <p className='text-center text-blue-800 font-bold'>THÊM VĂN BẢN LIÊN QUAN</p>
          <button className='text-gray-400 hover:text-gray-700 hover:bg-gray-200 py-1 px-2 rounded-lg' onClick={onClose}><IoIosClose size={25}/></button>
        </div><hr className='md:mx-[30%]'/>
        <form onSubmit={handleAdd}>
          <div className='flex flex-col overflow-auto'>  {/* h-[80vh] */}
            <div className='p-2 m-2 rounded-lg'>
              <div className=''>
                <div className='w-full'>
                  <label className="block text-sm font-medium">Số/ ký hiệu</label>
                  <input type="text"  value={data.KyHieu} disabled onChange={handleKyHieu} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 "/>
                </div>
                <div className='w-full'>
                  <label className="block text-sm font-medium">Số/ ký hiệu liên quan</label>
                  <input required list='vanbaKyHieu' type='search' autoFocus onChange={handleKyHieuLienQuan} value={KyHieuLienQuan} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 "/>
                  <datalist id='vanbaKyHieu'>
                  {KyHieuList
                    .filter((item) => item !== data.KyHieu) // Loại bỏ KyHieu hiện tại
                    .map((item, index) => (
                      <option key={index} value={item} />
                    ))}
                  </datalist>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-center py-2'>
             <button type="submit" className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold'>THÊM VĂN BẢN LIÊN QUAN</button>
           </div> 
        </form>
      </div>
    </div>
  </div>
  )
}
