import React, { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { loginRequestUser } from '../utils/azure/config'
import { useIsAuthenticated } from '@azure/msal-react';
import { GlobalState } from "../store/GlobalState";
import logoMyH from '../assets/images/logo-myH.png'
import logoHHHSG from '../assets/images/HHH-SG.png'
export default function Login() {
    const state = useContext(GlobalState)

    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [Auth, setAuth] = state.isAuthenticated

    useEffect(()=>{
        if(isAuthenticated){
            window.sessionStorage.setItem('islogged', true)
        }
    },[isAuthenticated])

    const handleLogin = () => {
        instance.loginPopup(loginRequestUser)
        .then(response => {
            const account = response.account;
            instance.setActiveAccount(account);
        })
        .catch(e => {
            console.error(e);
        });
    };

    return (
        <div className="bg-[#f6f6f6] w-full h-screen flex justify-center items-center p-2">
          <div className="shadow-lg rounded p-10 bg-white">
            <img src={logoHHHSG} alt="Logo Hung Hau" className="w-24 mx-auto" />
            {/* <p className="font-bold text-center text-gray-700 text-xl mt-4">Đăng nhập</p> */}
            <button onClick={handleLogin} className="flex justify-center py-5 px-20 border rounded my-3 hover:shadow hover:bg-slate-50 w-full items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" className="h-8">
                <path fill="#f3f3f3" d="M0 0h23v23H0z" /> <path fill="#f35325" d="M1 1h10v10H1z" /> <path fill="#81bc06" d="M12 1h10v10H12z" /> <path fill="#05a6f0" d="M1 12h10v10H1z" /> <path fill="#ffba08" d="M12 12h10v10H12z" />
              </svg>
              <p className="ml-2 text-lg text-gray-600">Login with Office 365</p>
            </button>
          </div>
        </div>
    )
}