import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpss } from '../utils/host';
import Headers from '../components/home/header';
import Footer from '../components/home/footer';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import EditsVanBan from '../components/layout/EditVanBan';
import AddVanBan from '../components/layout/AddVanBanLienQuan';
import { GlobalState } from '../store/GlobalState';

export default function Details() {
  const state = useContext(GlobalState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  // const [data, setData] = useState([]);
  const [vanBanItem, setVanBanItem] = useState([]);
  const navigator = useNavigate();
  useEffect(() => {
    GetData()
  },[id])

  const [ addVanBan, setAddVanBan ] = useState(false);
  const handleAddVanBan = () => {setAddVanBan(true)}
  const handleCloseAddVanBan = () => {setAddVanBan(false)}

  const [vanbanEdit, setVanbanEdit] = useState();
  const [Edit, setEdit] = useState(false);
  const handleEditVanBan = (item) => {setVanbanEdit(item);setEdit(true)}
  const handleCloseEdit = () => {setEdit(false)};

  const GetData = async () => {
    try {
      const url = `${httpss}/app/users/vanban?id=${id}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,          
        },
      });

      const data = await response.json();
      console.log(1,data)
      setVanBanItem(data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async (item) => {
    // console.log(item?.dataValues.vanbanId)
    if(!window.confirm(`Bạn có chắc chắn muốn xóa liên kết với ${item?.lienquanInfo?.TrichYeu} không`)) return
    try {
      const url = `${httpss}/app/users/delete_vanban_lienquan`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(
          { 
            vanbanId: item?.dataValues?.vanbanId, 
            lienquanId: item?.dataValues?.lienquanId 
          }
        ),
      });
      if (response.ok) {
        GetData()
        alert(`Xóa liên kết thành công`);
      } else {
        console.error('Request failed:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }

  }
  return (
    <div className=''>
      <Headers/>
      <div className='flex flex-col min-h-screen'>
        <div className='top-16 sticky z-10'>
          <button onClick={() => navigator('/')} className="fixed max-md:top-16 top-25 left-0 bg-[#0678BD] opacity-80 hover:bg-[#0678BD]/80 m-2 p-2 text-white text-xs font-bold rounded">
            <span className="hidden md:inline">Quay lại</span>
            <IoMdArrowRoundBack className='md:hidden'/>
          </button>
        </div>
        <div className='container mx-auto max-md:px-2 py-10'>
          <h1 className='text-3xl my-5'>
            Quyết định số {vanBanItem?.vb?.KyHieu} của {vanBanItem?.vb?.NoiBH}: {vanBanItem?.vb?.TrichYeu}
          </h1> 
          <table className='w-full'>
            <tr>
              <td className='border p-4 text-nowrap font-bold bg-gray-100'>Số, ký hiệu</td> 
              <td className='border p-4 w-2/3'>{vanBanItem?.vb?.KyHieu}</td>
            </tr>
            <tr>
              <td className='border p-4 text-nowrap font-bold bg-gray-100'>Ngày ban hành</td> 
              <td className='border p-4 '>{formatDate(vanBanItem?.vb?.NgayBanHanh)}</td>
            </tr>
            <tr>
              <td className='border p-4 text-nowrap font-bold bg-gray-100'>Loại văn bản</td> 
              <td className='border p-4 w-2/3'>{vanBanItem?.vb?.LoaiVBName.LoaiVBName}</td>
            </tr>
            <tr>
              <td className='border p-4 text-nowrap font-bold bg-gray-100'>Đơn Vị Ban Hành</td> 
              <td className='border p-4 w-2/3'>{vanBanItem?.vb?.NoiBHName?.NoiBHName}</td>
            </tr>
            <tr>
              <td className='border p-4 text-nowrap font-bold bg-gray-100'>Trích yếu</td> 
              <td className='border p-4 w-2/3'>{vanBanItem?.vb?.TrichYeu}</td>
            </tr>
            <tr>
              <td className='border p-4 text-nowrap font-bold bg-gray-100'>Tài liệu đính kèm</td> 
              <td className='border p-4 '>
                <a href={vanBanItem?.vb?.TepDinhKem} target="_blank" className='text-blue-500 font-bold hover:underline'>
                  {vanBanItem?.vb?.TenTep}
                </a>
              </td>
            </tr>
          </table>
          <div className='my-5'>
            {state?.userInfos?.userInfos[0].Role === 1 ? (
              <div className='flex justify-end gap-5'>
                <button className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold' onClick={() => {handleEditVanBan(vanBanItem)}}><CiEdit/></button>
                <button onClick={()=>{handleAddVanBan(vanBanItem)}} className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold'>Thêm văn bản liên quan</button>
              </div>
            ):(
              null
            )}

            {addVanBan && <AddVanBan onClose={handleCloseAddVanBan} data={vanBanItem?.vb} refreshData={GetData}/>}
            {Edit && <EditsVanBan onClose={handleCloseEdit} data={vanbanEdit?.vb} refreshData={GetData}/>}
            {Array.isArray(vanBanItem?.vb?.vanbanLienQuans) && vanBanItem?.vb?.vanbanLienQuans.length > 0 ? (
                <details open className='border p-2 my-5 rounded'>
                  <summary className="cursor-pointer text-2xl my-5">VĂN BẢN LIÊN QUAN</summary>
                  <ul>
                    {vanBanItem?.vb?.vanbanLienQuans.map((item,index) => (
                      <li key={item?.id} className="flex justify-between hover:bg-gray-100 p-2 rounded group">
                        <button className="text-blue-500 underline rounded px-3 w-full" onClick={() => navigator(`/details?id=${item?.dataValues?.lienquanId}`)} >
                          <p className='flex justify-start'>{item?.lienquanInfo?.TrichYeu}</p>  
                        </button>
                        {state?.userInfos?.userInfos[0].Role === 1 ? (
                          <div className="flex gap-2">
                            <button className="bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold"onClick={() => handleDelete(item)} >
                              <MdDeleteOutline />
                            </button>
                          </div>
                        ):(
                          null
                        )}
                      </li>
                    ))}
                  </ul>

                </details>
              ) : null
            }
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
