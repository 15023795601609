import React,{useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {DataProvider} from './store/GlobalState';
import MainPage from './components/Router'
const pageStyles = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
function App() {
  return (
    <DataProvider>
      <Router>
        <div className="w-full" style={pageStyles}>
          <MainPage/>
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;