import React, {useContext, useState, useEffect} from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound404 from '../pages/notFound404'
import Details from "../pages/Details";
import HomesVanBan from "../pages/HomesVanBan";
import { GlobalState } from '../store/GlobalState';
import LoginUser from './LoginUser';
import Deleted from '../pages/Deleted';

function App() {
  const state = useContext(GlobalState)
  const [islogged] = state.isAuthenticated
  const [checked, setChecked] = useState(false)

  useEffect(()=>{
    const checkLogin = ()=>{
      if(sessionStorage.getItem('islogged')){
        setChecked(true)
      }
    }
    checkLogin()
  })

  return (
    <Routes>
        <Route>
          <Route path="/" element={checked?(<HomesVanBan />):(<LoginUser/>)}/>
          <Route path="/:id" element={checked?(<Details />):(<LoginUser/>)}/>
          <Route path="/deleted" element={checked?(<Deleted />):(<LoginUser/>)}/>
          <Route path="*" element={<NotFound404 />} />
        </Route>
    </Routes>
  );
}

export default App;