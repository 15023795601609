import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { httpss } from '../utils/host';

export default function UserAPI() {
  const [userInfos, setUserInfo] = useState([]);
  const [callback, setCallback] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const accessToken = sessionStorage.getItem('accessToken');

  const fetchUsers = async () => {
    try { 
      const url = `${httpss}/app/users/info`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUserInfo(data);
      } else {
        console.error('Request failed:', response.status);
      }
    } catch (error) {
      console.error('Error:', error); 
    }
  }

  useEffect(() => {
    if (accessToken) {
      fetchUsers();
    }
  }, [accessToken, callback]); 

  return {
    userInfos: [userInfos, setUserInfo],
    refresh: [refresh, setRefresh],
    callback: [callback, setCallback]
  };
}
