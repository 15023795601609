import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { httpss } from '../utils/host';

export default function DataList() {
  const [dataList, setDataList] = useState();
  const [dataLoaiVB, setDataLoaiVB] = useState([]);
  const [dataLinhVuc, setDataLinhVuc] = useState([]);
  const [dataNoiBH, setDataNoiBH] = useState([]);
  const [callback, setCallback] = useState(false);
  const token = sessionStorage.getItem('accessToken')

  const handleDataAll = async () => {
    if(token){
      try { 
        const url = `${httpss}/app/users/vanbans_list`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setDataList(data)
          // console.log(1,data)
          // console.log(1,data.LinhVucs)
          // setDataLoaiVB(data.LoaiVBs)
          // setDataLinhVuc(data.LinhVucs)
          // setDataNoiBH(data.NoiBHs)
        } else {
          console.error('Request failed:', response.status);
        }
      } catch (error) {
        console.error('Error:', error); 
      }
    } else {
      console.log('Chưa đăng nhập');
    }
  }

  useEffect(() => {
    handleDataAll()
  }, [callback,token]);

  return {
    // dataLinhVuc: [dataLinhVuc, setDataLinhVuc],
    // dataLoaiVB: [dataLoaiVB, setDataLoaiVB],
    // dataNoiBH: [dataNoiBH, setDataNoiBH],
    dataList: [dataList, setDataList],
    callback: [callback, setCallback]
  };
}
