import React, { useState, useEffect, createContext } from 'react';
import VanBanAPI from '../Apis/dataVanBan';
import { useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { useIsAuthenticated } from '@azure/msal-react';
import DataList from '../Apis/dataList';
import UserInfos from '../Apis/user'

export const GlobalState = createContext();

export const DataProvider = ({ children }) => {
  const [token, setToken] = useState('')
  const [dataUser, setDataUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const { accounts, instance } = useMsal();
  const isAuthenticated2 = useIsAuthenticated();

  useEffect(()=>{
    setIsAuthenticated(window.sessionStorage.getItem('islogged'))
  })

useEffect(() => { 
  const fetchToken = async () => {
      if (accounts.length > 0) {
          instance.setActiveAccount(accounts[0]);
      }

      const accessTokenRequest = {
          scopes: ["api://64108941-8476-4d57-8f86-a75ac1919844/User","offline_access"],
          account: instance.getActiveAccount()
      };

      if (accessTokenRequest.account) {
          try {
              const response = await instance.acquireTokenSilent(accessTokenRequest);
              const accessToken = response.accessToken;
              sessionStorage.setItem('accessToken', accessToken);
              setToken(accessToken);
              // console.log(accessToken)
          } catch (error) {
              console.error("Error acquiring access token:", error);
              if (error instanceof InteractionRequiredAuthError) {
                  try {
                      const response = await instance.acquireTokenPopup(accessTokenRequest);
                      const accessToken = response.accessToken;
                      sessionStorage.setItem('accessToken', accessToken);
                      setToken(accessToken);
                      // console.log(accessToken)
                  } catch (popupError) {
                      console.error("Error acquiring access token with popup:", popupError);
                  }
              }
          }
      }
  };
  fetchToken();
}, [accounts, instance, isAuthenticated2, isAuthenticated]);

  const state = {
    token: [token, setToken],
    isAuthenticated: [isAuthenticated, setIsAuthenticated],
    dataList: DataList(),
    userInfos: UserInfos(),
    // pages: AccountAPI(),
    // listPages: ListPages(),
    // pagesAdmin: PageAdminPageAPI(),
  };

  return (
    <GlobalState.Provider value={state}>
      {children}
    </GlobalState.Provider>
  );
};
