import React from 'react';
import '../../styles/styleHome.css'

export default function Noti({ message, onClose }) {
  return (
    <div className='z-10 w-full h-full fixed top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-gray-300/60'>
      <div className='fixed top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 flex justify-center items-center'>
        <div className="zoom-in bg-white p-3 rounded border border-gray-400">
          <p className='text-center text-[#0048E0] font-bold'>THÔNG BÁO</p><hr/>
          <p className='p-2 mb-5 text-[#0048E0]'>{message}</p>
          <div className='flex justify-end'>
            <button className='bg-[#0048E0] hover:bg-[#0048E0]/80 text-white rounded py-1 px-5 justify-items-end font' onClick={onClose}>Đóng</button>
          </div>  
        </div>
      </div>
    </div>
  );
}
