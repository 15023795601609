import React, { useState, useEffect, useContext } from 'react'
import { IoIosClose } from "react-icons/io";
import { httpss } from '../../utils/host';
import dataSelectSearch from '../../assets/data/select.json';
import { GlobalState } from '../../store/GlobalState';

export default function AddVanBan({onClose, refreshData, data}) {
  const state = useContext(GlobalState);
  // console.log("home",state)
  const LinhVucList = state?.dataList?.dataList[0]?.LinhVucs.map(item => item.LinhVucName);
  const NoiBHList = state?.dataList?.dataList[0]?.NoiBHs.map(item => item.NoiBHName);
  const LoaiVBList = state?.dataList?.dataList[0]?.LoaiVBs.map(item => item.LoaiVBName);

  const [callback, setCallback] = state.dataList.callback

  const [KyHieu, setKyHieu] = useState('');
  const [LoaiVB, setLoaiVB] = useState('');
  const [NoiBH, setNoiBH] = useState('');
  const [TrichYeu, setTrichYeu] = useState('');
  const [LinhVuc, setLinhVuc] = useState('');
  const [TepDinhKem, setTepDinhKem] = useState('');
  const [TenTep, setTenTep] = useState('');
  const [NgayBanHanh, setNgayBanHanh] = useState('');
  
  const handleKyHieu = (e) => {setKyHieu(e.target.value)}
  const handleLoaiVB = (e) => {setLoaiVB(e.target.value)}
  const handleNoiBH = (e) => {setNoiBH(e.target.value)}
  const handleTrichYeu = (e) => {setTrichYeu(e.target.value)}
  const handleLinhVuc = (e) => {setLinhVuc(e.target.value)}
  const handleTepDinhKem = (e) => {
    const url = e.target.value;
    setTepDinhKem(url);
    const fileName = extractFileNameFromUrl(url);
    setTenTep(fileName); // Cập nhật tên tệp
  };
  const hanldeTenTep = (e) => {setTenTep(e.target.value)}
  const handleNgayBanHanh = (e) => {
    setNgayBanHanh(e.target.value)
  }
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setNgayBanHanh(today); // Đặt ngày mặc định
  }, []);
  const extractFileNameFromUrl = (url) => {
    try {
      const params = new URLSearchParams(url.split('?')[1]);  
      const idParam = params.get('id'); 
  
      if (idParam) {
        const decodedId = decodeURIComponent(idParam);  
        const parts = decodedId.split('/'); 
        return parts[parts.length - 1];  
      }
  
      const urlParts = url.split('/');
      return urlParts[urlParts.length - 1]; 
    } catch (error) {
      console.error('Invalid URL:', error);
    }
    return ''; 
  };
  

  const handleClose = (e) => {e.target.id === 'form' && onClose()};

  const handleAdd = async (e) => {
    e.preventDefault()
    try {
      const url = `${httpss}/app/users/vanban`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
        }, body: JSON.stringify({ KyHieu, LoaiVB, NoiBH, TrichYeu, LinhVuc, TepDinhKem, TenTep, NgayBanHanh })});
        
          if (response.ok) {
            const result = await response.json();
            setCallback(!callback)
            onClose()
        } else {
          console.error('Request failed:', response.status);
          alert('Vui lòng thử lại')}
      } catch (error) {console.error('Lỗi:', error); }
    };

  return (
    <div id="form" className='z-10 w-full h-full fixed top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-black/60'>
    <div id="form" className='fixed mx-auto container px-5 top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 flex justify-center items-center'>
      <div className="bg-white p-3 rounded-lg border w-full">
        <div className='flex items-center justify-between text-[#0048E0] font-bold'>
          <div></div>
          <p className='text-center text-blue-800 font-bold'>THÊM VĂN BẢN</p>
          <button className='text-gray-400 hover:text-gray-700 hover:bg-gray-200 py-1 px-2 rounded-lg' onClick={onClose}><IoIosClose size={25}/></button>
        </div><hr className='md:mx-[30%]'/>
        <form onSubmit={handleAdd}>
          <div className='flex flex-col overflow-auto'>  {/* h-[80vh] */}
            <div className='p-2 m-2 rounded-lg'>
              <div className='md:flex gap-3 mb-2'>
                <div className='w-full'>
                  <label className="block text-sm font-medium">Số/ ký hiệu</label>
                  <input required type="text" autoFocus value={KyHieu} onChange={handleKyHieu} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                </div>
                <div className='w-full'>
                  <label className='block text-sm font-medium'>Ngày ban hành:</label>
                  <input required onChange={handleNgayBanHanh} value={NgayBanHanh} type="date" className='w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow'/>
                </div>
              </div>
              <div className='md:flex gap-3 mb-2'>
                <div className='w-full'>
                  <label className="block text-sm font-medium">Loại văn bản</label>
                  <input required list='loaiVB' type='search' onChange={handleLoaiVB} value={LoaiVB} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                  <datalist id='loaiVB'>
                    {LoaiVBList?.map((item, index) => (
                      <option key={index} value={item}/>
                    ))}
                  </datalist>
                </div>
                <div className='w-full'>
                  <label className="block text-sm font-medium">Đơn Vị Ban Hành</label>
                  <input required list='noiBH' type='search' onChange={handleNoiBH} value={NoiBH} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                  <datalist id='noiBH'>
                      {NoiBHList?.map((item, index) => (
                        <option key={index} value={item}/>
                      ))}
                  </datalist>
                </div>
              </div>
              <div className='mb-2'>
                <label className="block text-sm font-medium">Trích yếu</label>
                <input required type="text" value={TrichYeu} onChange={handleTrichYeu} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
              </div>
                <div className='w-full'>
                    <label className="block text-sm font-medium">Lĩnh vực</label>
                    <input required list='linhVuc' type='search' value={LinhVuc} onChange={handleLinhVuc} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                    <datalist id='linhVuc'>
                      {LinhVucList?.map((item, index) => (
                        <option key={index} value={item}/>
                      ))}
                    </datalist>
                  </div>
                  <div className='mb-2'>
                    <label className="block text-sm font-medium">Link tài liệu đính kèm (SharePoint)</label>
                    <textarea required type="text" value={TepDinhKem} onChange={handleTepDinhKem} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                  </div>

                  <div className='mb-2'>
                    <label className="block text-sm font-medium">Tên tệp</label>
                    <input required type="text" value={TenTep} onChange={hanldeTenTep} className="w-full p-2 border border-gray-300 hover:border-sky-500 outline-none rounded mb-2 shadow"/>
                  </div>

            </div>
          </div>
          <div className='flex justify-center py-2'>
             <button type="submit" className='bg-[#0678BD] hover:bg-[#0678BD]/80 text-white rounded py-1 px-10 justify-items-end font shadow uppercase font-bold'>THÊM VĂN BẢN</button>
           </div> 
        </form>
      </div>
    </div>
  </div>
  )
}
