import React from 'react'

export default function Footer() {
  return (
    <div className='max-md:hidden border-t'>
      <div className="bg-white">
        <div className="container mx-auto">
          <div className="text-center text-xs py-2 md:flex gap-2 justify-center">
            <p>© 2025 - Phát triển bởi Ban Chuyển Đổi Số</p> 
            <p>-</p>
            <a className='text-[#0B79BF] hover:underline' href="https://hunghau.vn" target="_blank">HungHau Holdings</a>
          </div>
        </div>
      </div>
    </div>
  )
}
